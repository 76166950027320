import api from '@/services/api/api';

const defaultOptions = {
  staleTime: Infinity,
  refetchOnFocus: false,
  refetchOnReconnect: false,
};

export const useThisDayInHistory = (options = {}) => {
  const thisDayInHistory = useQuery(
    ['thisDayInHistory'],
    async () => await api.others.getDayInHistory(),
    { ...defaultOptions, ...options, select: ({ data }) => data },
  );

  thisDayInHistory.suspense();

  return {
    thisDayInHistory,
  };
};
