import api from '@/services/api/api';

export const useRandomAudioLectures = (per_page = 4, exclude) => {
  const randomAudioLectures = useQuery(
    ['randomAudioLectures'],
    async () => await api.lectures.getRandomAudioLecture(per_page, exclude),
    {
      select: ({ data }) => data?.data,
    },
  );

  randomAudioLectures.suspense();

  return {
    randomAudioLectures,
  };
};
