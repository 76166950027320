export default [
  {
    model_type: 'specialproject',
    slug: 'https://histrf.ru/read/science/aktualnye-issledovaniya-sovremennyh-istorikov-8',
    image: {
      preview: '/projects/other/science-4.png',
    },
    title: '',
    announce: '',
    isBlank: false,
  },
  {
    model_type: 'specialproject',
    slug: 'https://histrf.ru/read/science/aktualnye-issledovaniya-sovremennyh-istorikov-2',
    image: {
      preview: '/projects/other/science-2.jpg',
    },
    title: '',
    announce: '',
    isBlank: false,
  },
  {
    model_type: 'specialproject',
    slug: 'https://histrf.ru/read/science/aktualnye-issledovaniya-sovremennyh-istorikov-3',
    image: {
      preview: '/projects/other/science-1.jpeg',
    },
    title: '',
    announce: '',
    isBlank: false,
  },
  {
    model_type: 'specialproject',
    slug: 'https://histrf.ru/read/science/aktualnye-issledovaniya-sovremennyh-istorikov',
    image: {
      preview: '/projects/other/science-3.jpg',
    },
    title: '',
    announce: '',
    isBlank: false,
  },
];
