<template>
  <div class="flex items-center">
    <button
      v-for="(_, i) in total"
      :key="i"
      type="button"
      class="mr-5 size-[10px] rounded-full last:mr-0"
      :class="{
        'bg-[#D9D9D9]': i !== current,
        'bg-specialprojectsection': i === current,
      }"
      @click="emit('click', i)"
    />
  </div>
</template>

<script setup>
defineProps({
  total: {
    type: Number,
    default: 0,
  },

  current: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['click']);
</script>

<style scoped lang="scss"></style>
