<template>
  <div class="flex h-full flex-col">
    <div class=""></div>

    <div class="grid-day grid h-full" :class="computedClass">
      <p v-if="data.yesterday.data.length" class="y-title day-title">Вчера</p>
      <div class="yesterday-top">
        <MainPageCardsReworkedBaseCard
          v-if="data.yesterday.data[0]"
          class="h-full"
          :data="data.yesterday.data[0]"
          disable-announce
        />
      </div>
      <div class="yesterday-bottom">
        <MainPageCardsReworkedBaseCard
          v-if="data.yesterday.data[1]"
          class="h-full"
          :data="data.yesterday.data[1]"
          disable-announce
        />
      </div>
      <p v-if="data.today.data[0]" class="tod-title day-title">В этот день</p>
      <div class="today">
        <MainPageCardsReworkedBaseCard
          v-if="data.today.data[0]"
          class="h-full"
          :data="data.today.data[0]"
        />
      </div>
      <p v-if="data.tomorrow.data.length" class="tom-title day-title">Завтра</p>
      <div class="tomorrow-top">
        <MainPageCardsReworkedBaseCard
          v-if="data.tomorrow.data[0]"
          class=""
          :data="data.tomorrow.data[0]"
          disable-announce
        />
      </div>
      <div class="tomorrow-bottom">
        <MainPageCardsReworkedBaseCard
          v-if="data.tomorrow.data[1]"
          class="col-span-full lg:col-span-2 lg:col-start-3 lg:row-start-2 xl:col-span-1 xl:col-start-4"
          :data="data.tomorrow.data[1]"
          disable-announce
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
});
const { isMobile } = useBreakpoint();

const computedClass = computed(() => {
  if (isMobile.value) return '';
  const classes = {
    'not-bottom-yesterday': props.data?.yesterday?.data?.length === 1,
    'not-today': !props.data?.today?.data?.length,
    'not-bottom-tomorrow': props.data?.tomorrow?.data?.length === 1,
  };

  return Object.values(classes).some((val) => val) ? classes : 'full';
});
</script>

<style lang="scss" scoped>
.day-title {
  @apply text-[32px] leading-[38px] text-[#1f1f1f] font-roboto font-medium;
}
.y-title {
  grid-area: y-t;
}
.yesterday-top {
  grid-area: y-top;
}
.yesterday-bottom {
  grid-area: y-bottom;
}
.tod-title {
  grid-area: td-t;
}
.today {
  grid-area: td;
}
.tom-title {
  grid-area: tm-t;
}
.tomorrow-top {
  grid-area: tm-top;
}
.tomorrow-bottom {
  grid-area: tm-bottom;
}

.grid-day {
  $grid-day-gap: 20px;
  gap: $grid-day-gap;
  grid-template:
    'y-t td-t tm-t' 38px
    'y-top td tm-top' 1fr
    'y-bottom td tm-bottom' 1fr / 1fr 2fr 1fr;
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .grid-day {
    $grid-day-gap: 12px;
    gap: $grid-day-gap;
  }
  .grid-day.full {
    grid-template:
      'y-t . td-t td-t . .' 38px
      'y-top y-top td td td td' 1fr
      'y-bottom y-bottom td td td td' 1fr
      'tm-t . . . . .' 38px
      'tm-top tm-top tm-top tm-bottom tm-bottom tm-bottom' 1.5fr / 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .grid-day.not-top-yesterday {
    grid-template:
      'y-t y-t y-t td-t td-t td-t' 38px
      'y-bottom y-bottom y-bottom td td td' 0.5fr
      'y-bottom y-bottom y-bottom td td td' 0.5fr
      'tm-t . . . . .' 38px
      'tm-top tm-top tm-top tm-bottom tm-bottom tm-bottom' 1.5fr / 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .grid-day.not-bottom-yesterday {
    grid-template:
      'y-t y-t y-t td-t td-t td-t' 38px
      'y-top y-top y-top td td td' 0.5fr
      'y-top y-top y-top td td td' 0.5fr
      'tm-t . . . . .' 38px
      'tm-top tm-top tm-top tm-bottom tm-bottom tm-bottom' 1.5fr / 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .grid-day.not-today {
    grid-template:
      'y-t y-t y-t . . .' 38px
      'y-top y-top y-top y-bottom y-bottom y-bottom' 0.5fr
      'y-top y-top y-top y-bottom y-bottom y-bottom' 0.5fr
      'tm-t . . . . .' 38px
      'tm-top tm-top tm-top tm-bottom tm-bottom tm-bottom' 1.5fr / 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .grid-day.not-top-tomorrow {
    grid-template:
      'y-t . td-t td-t . .' 38px
      'y-top y-top td td td td' 0.5fr
      'y-bottom y-bottom td td td td' 0.5fr
      'tm-t . . . . .' 38px
      'tm-bottom tm-bottom tm-bottom tm-bottom tm-bottom tm-bottom' 1.5fr / 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .grid-day.not-bottom-tomorrow {
    grid-template:
      'y-t . td-t td-t . .' 38px
      'y-top y-top td td td td' 0.5fr
      'y-bottom y-bottom td td td td' 0.5fr
      'tm-t . . . . .' 38px
      'tm-top tm-top tm-top tm-top tm-top tm-top' 1.5fr / 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 1024px) {
  .grid-day.full {
    grid-template:
      'y-t y-t ' 38px
      'y-top y-bottom' auto
      'td-t td-t' 38px
      'td td' auto
      'tm-t tm-t ' 38px
      'tm-top tm-bottom' auto / 1fr 1fr;
  }
  .grid-day.not-top-yesterday {
    grid-template:
      'y-t y-t ' 38px
      'y-bottom y-bottom' auto
      'td-t td-t' 38px
      'td td' auto
      'tm-t tm-t ' 38px
      'tm-top tm-bottom' auto / 1fr 1fr;
  }
  .grid-day.not-bottom-yesterday {
    grid-template:
      'y-t y-t ' 38px
      'y-top y-top' auto
      'td-t td-t' 38px
      'td td' auto
      'tm-t tm-t ' 38px
      'tm-top tm-bottom' auto / 1fr 1fr;
  }
  .grid-day.not-top-tomorrow {
    grid-template:
      'y-t y-t ' 38px
      'y-top y-bottom' auto
      'td-t td-t' 38px
      'td td' auto
      'tm-t tm-t ' 38px
      'tm-bottom tm-bottom' auto / 1fr 1fr;
  }
  .grid-day.not-bottom-tomorrow {
    grid-template:
      'y-t y-t ' 38px
      'y-top y-bottom' auto
      'td-t td-t' 38px
      'td td' auto
      'tm-t tm-t ' 38px
      'tm-top tm-top' auto / 1fr 1fr;
  }
}
@media screen and (max-width: 768px) {
  .grid-day {
    grid-template:
      'y-t' 38px
      'y-top' auto
      'y-bottom' auto
      'td-t' 38px
      'td' auto
      'tm-t' 38px
      'tm-bottom' auto
      'tm-top' auto / 1fr;
  }
}
</style>
