<template>
  <div>
    <MainPageCardsReworkedBaseCard
      v-if="isSingleElement"
      :data="data[0]"
      title-card
      :disable-announce="disableAnnounce"
      :disable-author="disableAuthor"
      :disable-title="disableTitle"
      :disable-label="disableLabel"
      :is-blank="isBlank"
    />
    <div v-else class="relative">
      <ClientOnly>
        <div>
          <Swiper @swiper="onSwiper">
            <SwiperSlide
              v-for="(item, index) in data"
              :key="item.id || index"
              class="swiper-slide"
            >
              <MainPageCardsReworkedBaseCard
                :data="item"
                :disable-announce="disableAnnounce"
                :disable-author="disableAuthor"
                :disable-title="disableTitle"
                :disable-label="disableLabel"
                :is-blank="isBlank"
                :is-pagination="isPagination"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </ClientOnly>

      <SlidersSliderControl
        type="prev"
        class="absolute left-[-16px] top-1/2 z-10 hidden -translate-y-1/2 md:block"
        :class="[isBeginning ? 'opacity-50' : 'cursor-pointer']"
        @click="slidePrev()"
      />
      <SlidersSliderControl
        type="next"
        class="absolute right-[-16px] top-1/2 z-10 hidden -translate-y-1/2 md:block"
        :class="[isEnd ? 'opacity-50' : 'cursor-pointer']"
        @click="slideNext()"
      />

      <SlidersSliderPagination
        :total="data?.length"
        :current="currentSlideIndex"
        class="absolute bottom-3 left-1/2 z-10 -translate-x-1/2"
        @click="handlePagination"
      />
    </div>
  </div>
</template>

<script setup>
import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/vue';

const props = defineProps({
  data: { type: Array, required: true },
  disableAnnounce: { type: Boolean, default: false },
  disableAuthor: { type: Boolean, default: false },
  disableTitle: { type: Boolean, default: false },
  disableLabel: { type: Boolean, default: false },
  isBlank: { type: Boolean, default: false },
  isPagination: { type: Boolean, default: false },
});

const { swiper, onSwiper, slideNext, slidePrev, isBeginning, isEnd } =
  useSwiper();

const currentSlideIndex = computed(() => swiper.value.activeIndex);

const isSingleElement = computed(() => {
  return props.data.length === 1;
});

const handlePagination = (e) => {
  swiper.value.slideTo(e);
};
</script>

<style scoped lang="scss"></style>
