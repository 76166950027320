export default [
  {
    model_type: 'audiocourse',
    slug: 'aleksandr-iii-konservativnyy-variant-modernizacii-rossii',
    image: {
      preview: '/projects/other/audio_1.jpeg',
    },
    title: '',
    announce: '',
    isBlank: false,
  },
  {
    model_type: 'audiolecture',
    slug: 'aleksandr-iii-konservativnyy-variant-modernizacii-rossii',
    image: {
      preview: '/projects/other/audio_2.jpeg',
    },
    title: 'Александр III: консервативный вариант модернизации России',
    announce: '',
    isBlank: false,
  },
  {
    model_type: 'audiocourse',
    slug: 'drugoe-filosofiya-teoriya-i-istoriya-istoricheskogo-poznaniya',
    image: {
      preview: '/projects/other/audio_3.jpg',
    },
    title: 'Философия, теория и история исторического познания',
    announce: '',
    isBlank: false,
  },
  {
    model_type: 'audiolecture',
    slug: 'pozitivnaya-istoriya-rossii-idealisticheskiy-podhod',
    image: {
      preview: '/projects/other/audio_4.jpeg',
    },
    title: 'Позитивная история России. Идеалистический подход',
    announce: '',
    isBlank: false,
  },
  {
    model_type: 'audiolecture',
    slug: 'russkaya-armiya-v-osvoboditelnom-pohode-v-evrope-v-1813-1814-godah',
    image: {
      preview: '/projects/other/audio_5.jpeg',
    },
    title: 'Русская армия в освободительном походе в Европе в 1813-1814 годах',
    announce: '',
    isBlank: false,
  },
];
