<template>
  <MainPageBlockWrapperNew
    v-if="data?.length"
    title="Аудиолекции, аудиокниги и подкасты"
    href="/listen/lectures"
  >
    <div
      class="flex grid-cols-4 grid-rows-2 flex-wrap justify-between gap-[20px] xl:grid"
    >
      <MainPageCardsReworkedBaseCard
        v-if="isDesktop"
        class="col-span-full w-full md:w-[48.555%] lg:col-span-2 lg:col-start-1 lg:row-span-2 xl:col-start-2 xl:w-full"
        :data="data[0]"
        disable-announce
        disable-label
        disable-author
      />
      <MainPageCardsReworkedBaseCard
        v-else
        class="col-span-full w-full md:w-[48.555%] lg:col-span-2 lg:col-start-3 lg:row-start-1 xl:col-span-1 xl:w-full"
        :data="data[0]"
        disable-announce
        disable-label
        disable-author
      />
      <MainPageCardsReworkedBaseCard
        class="col-span-full w-full md:w-[48.555%] lg:col-span-2 lg:col-start-3 lg:row-start-1 xl:col-span-1 xl:w-full"
        :data="data[1]"
        disable-announce
        disable-label
        disable-author
      />
      <MainPageCardsReworkedBaseCard
        class="col-span-full w-full md:w-[48.555%] lg:col-span-2 lg:col-start-3 lg:row-start-2 xl:col-span-1 xl:w-full"
        :data="data[2]"
        disable-announce
        disable-label
        disable-author
      />
      <MainPageCardsReworkedBaseCard
        class="col-span-full w-full md:w-[48.555%] lg:col-span-1 lg:col-start-4 lg:row-start-1 xl:w-full"
        :data="data[3]"
        disable-announce
        disable-label
        disable-author
      />
      <MainPageCardsReworkedBaseCard
        class="col-span-full hidden w-full md:w-[48.555%] lg:col-span-1 lg:col-start-4 lg:row-start-2 xl:block xl:w-full"
        :data="data[4]"
        disable-announce
        disable-label
        disable-author
      />
    </div>
  </MainPageBlockWrapperNew>
</template>

<script setup>
import audioData from '@/assets/data/special-projects/audio';

const { isDesktop } = useBreakpoint();

const { randomAudioLectures } = useRandomAudioLectures(5, []);

const data = computed(() => {
  const lectures = randomAudioLectures?.data?.value;
  if (!lectures) return;
  return [lectures[0], ...audioData.slice(1, 5)];
});
</script>
