<template>
  <div>
    <div class="mb-[20px] flex items-center justify-between gap-[20px]">
      <h2
        class="font-roboto text-[32px] font-medium leading-[38px] text-new-test-black"
      >
        Библиотека военного историка
      </h2>
      <NuxtLink
        to="library"
        class="text-[18px] font-medium leading-[22px] text-new-test-black"
      >
        Смотреть все
      </NuxtLink>
    </div>
    <div class="relative">
      <ClientOnly>
        <div>
          <Swiper
            :breakpoints="breakpoints"
            :slides-per-view="1"
            :space-between="20"
            @swiper="onSwiper"
          >
            <SwiperSlide v-for="(project, index) in home" :key="index">
              <MainPageCardsReworkedBaseCard
                :aspect="{ w: 1, h: 1 }"
                :data="project"
                position-image="object-top"
                :is-blank="project.isBlank"
                disable-label
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </ClientOnly>

      <SlidersSliderControl
        type="prev"
        class="absolute left-[-16px] top-45% z-10 hidden md:block"
        :class="isBeginning ? 'opacity-50' : 'cursor-pointer'"
        @click="slidePrev()"
      />
      <SlidersSliderControl
        type="next"
        class="absolute right-[-16px] top-45% z-10 hidden md:block"
        :class="isEnd ? 'opacity-50' : 'cursor-pointer'"
        @click="slideNext()"
      />
    </div>
  </div>
</template>

<script setup>
import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/vue';

import home from '@/assets/data/special-projects/home';
const { onSwiper, slideNext, slidePrev, isBeginning, isEnd } = useSwiper();

const breakpoints = {
  1024: {
    slidesPerView: 2,
  },
  1280: {
    slidesPerView: 3,
  },
};
</script>

<style lang="scss" scoped>
.swiper-button-prev,
.swiper-button-next {
  @apply hidden lg:block;
}
</style>
