import api from '@/services/api/api';

const defaultOptions = {
  staleTime: Infinity,
  refetchOnFocus: false,
  refetchOnReconnect: false,
};

export const useVideoMaterials = (params = { options: {}, per_page: 9 }) => {
  const videoMaterials = useQuery(
    ['videoMaterials'],
    async () => await api.lectures.getRandomVideoMaterials(params.per_page),
    { ...defaultOptions, ...params.options, select: ({ data }) => data?.data },
  );

  videoMaterials.suspense();

  return {
    videoMaterials,
  };
};
