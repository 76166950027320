<template>
  <div>
    <MainPageBlockWrapperNew
      title="Актуальные исследования современных историков"
      href="/read/science"
    >
      <div class="relative">
        <ClientOnly>
          <div>
            <Swiper
              :breakpoints="breakpoints"
              :slides-per-view="1"
              :space-between="20"
              @swiper="onSwiper"
            >
              <SwiperSlide
                v-for="(project, index) in science"
                :key="index"
                class="swiper-slide w-full"
              >
                <MainPageCardsReworkedBaseCard
                  :aspect="{ w: 1, h: 1 }"
                  :data="project"
                  position-image="object-top"
                  :is-blank="project.isBlank"
                  disable-label
                  disable-title
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </ClientOnly>

        <SlidersSliderControl
          type="prev"
          class="absolute left-[-16px] top-45% z-10 hidden md:block"
          :class="isBeginning ? 'opacity-50' : 'cursor-pointer'"
          @click="slidePrev"
        />
        <SlidersSliderControl
          type="next"
          class="absolute right-[-16px] top-45% z-10 hidden md:block"
          :class="isEnd ? 'opacity-50' : 'cursor-pointer'"
          @click="slideNext"
        />
      </div>
    </MainPageBlockWrapperNew>
  </div>
</template>

<script setup>
import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/vue';

import science from '@/assets/data/special-projects/science';

const { onSwiper, slideNext, slidePrev, isBeginning, isEnd } = useSwiper();

const breakpoints = {
  1024: {
    slidesPerView: 2,
  },
  1280: {
    slidesPerView: 3,
  },
};
</script>

<style lang="scss" scoped>
.swiper-button-prev,
.swiper-button-next {
  @apply hidden lg:block;
}
</style>
