import api from '@/services/api/api';

const defaultOptions = {
  staleTime: Infinity,
  refetchOnFocus: false,
  refetchOnReconnect: false,
};

export const useThematicCollection = (
  options = {
    staleTime: Infinity,
    refetchOnFocus: false,
    refetchOnReconnect: false,
  },
) => {
  const thematicCollections = useQuery(
    ['thematicCollections'],
    async () => await api.collections.getThematic(),
    { ...defaultOptions, ...options, select: ({ data }) => data?.data },
  );

  thematicCollections.suspense();

  return {
    thematicCollections,
  };
};
