// import journals from '@/assets/data/special-projects/journal'

// const lastJournal = {
//   ...journals.gallery[0],
//   link: '/ideology_of_future',
// }

export default [
  {
    model_type: 'specialproject',
    slug: 'https://dostoevsky-club.ru/search?q=СЗР',
    image: {
      preview: '/projects/special/forager.jpg',
    },
    title: 'Собиратели Земли Русской',
    announce: '',
    isBlank: true,
  },
  {
    model_type: 'specialproject',
    slug: '/read/articles/novaya-kniga-iz-serii-rasskazy-iz-russkoy-istorii-vladimira-medinskogo',
    image: {
      preview: '/projects/other/generals.jpeg',
    },
    title: '',
    announce: '',
    isBlank: false,
  },
  {
    model_type: 'specialproject',
    slug: 'https://rvio.histrf.ru/storage/media/o_russkoj_smekalke_na_vojne.pdf',
    image: {
      preview: '/projects/special/russkoj_smekalke.jpg',
    },
    title: 'О русской смекалке на войне',
    announce:
      'Немецкая брошюра "Русские подручные средства и находчивость" (1944 г.) с научным комментарием',
    isBlank: true,
  },
  // {
  //   model_type: 'specialproject',
  //   slug: 'https://histrf.ru/female-heroes',
  //   image: {
  //     preview: require('~/static/projects/special/women-heroes.png'),
  //   },
  //   title: 'Женщины-герои',
  //   announce:
  //     'Проект посвящен женщинам, чьи поступки могут служить примером всем нам.',
  // },
  {
    model_type: 'specialproject',
    slug: 'https://histrf.ru/files/black_book_1941-1945-en.pdf',
    image: {
      preview: '/projects/other/black_book_en.jpg',
    },
    title: 'The Black Book',
    announce: 'Atrocities and massacres committed by Banderaites',
    isBlank: true,
  },
  {
    model_type: 'specialproject',
    slug: 'https://vk.cc/cp1kko',
    image: {
      preview: '/projects/other/black_book.jpg',
    },
    title: 'Черная книга',
    announce: 'Зверства бандеровцев 1941-1945',
    isBlank: true,
  },
  {
    model_type: 'specialproject',
    slug: 'https://vk.cc/cp1kP1',
    image: {
      preview: '/projects/other/black_book_2014-2023.jpeg',
    },
    title: 'Черная книга',
    announce:
      'Зверства современных бандеровцев - украинских неонацистов 2014-2023',
    isBlank: true,
  },

  //                                                                                                                                  ARCHIVE

  // {
  //   model_type: 'specialproject',
  //   slug: 'http://nurembergtrial.histrf.ru/',
  //   image: {
  //     preview: require('~/static/projects/special/nurmberg.jpg'),
  //   },
  //   title: 'Нацистская политика уничтожения',
  //   announce: '',
  // },
  // {
  //   model_type: 'specialproject',
  //   slug: 'https://history.vz.ru',
  //   image: {
  //     preview: require('~/static/projects/special/the-real-history-of-russia.jpg'),
  //   },
  //   title: 'Настоящая история России',
  //   announce: '',
  //   card_label: 'Проект партнера',
  // },
  // {
  //   model_type: 'specialproject',
  //   slug: 'http://100.histrf.ru/',
  //   image: {
  //     preview: require('~/static/projects/special/100-great-generals.png'),
  //   },
  //   title: '100 великих полководцев',
  //   announce:
  //     'Любители и знатоки военной истории вместе с учеными историками, начиная с 9 Мая 2013 г., выдвигали в список 100 великих тех военачальников, которые стали творцами военной славы России.',
  // },
  // {
  //   model_type: 'specialproject',
  //   slug: 'https://histrf.ru/collections/vyazemskiy-desant-75',
  //   image: {
  //     preview: require('~/static/projects/special/vyazemskiy-desant-square.png'),
  //   },
  //   title: 'Вяземский десант',
  //   announce:
  //     'Вяземская воздушно-десантная операция остается мало известной страницей Великой Отечественной. Это несправедливо по отношению к тысячам наших солдат, которые в лютые морозы 1942 года десантировались в немецком тылу, немедленно заняв круговую оборону и перейдя к активным боевым действиям.',
  // },
  // {
  //   model_type: 'specialproject',
  //   slug: 'https://w.histrf.ru',
  //   image: {
  //     preview: require('~/static/projects/special/great-encyclopedia.png'),
  //   },
  //   title: 'Энциклопедия «Всемирная история»',
  //   announce: '',
  // },
  // {
  //   model_type: 'specialproject',
  //   slug: 'https://histrf.ru/read/articles/istoriya-rossii-po-vekam',
  //   image: {
  //     preview: require('~/static/projects/special/history-by-centuries.png'),
  //   },
  //   title: 'История России по векам',
  //   announce:
  //     'Много изучено документов, много найдено вещественных доказательств… И то, что известно об истории России, легло в основу таблицы, которую мы предлагаем вашему вниманию.',
  // },
  // {
  //   model_type: 'specialproject',
  //   slug: 'https://histrf.ru/collections/great-victory',
  //   image: {
  //     preview: require('~/static/projects/special/great-victory.png'),
  //   },
  //   title: 'Великая победа',
  //   announce:
  //     'Интернет-проект подготовлен на основе современных представлений ведущих отечественных историков академических институтов и образовательных центров и ряда иностранных ученых-историков о событиях Второй мировой войны и периоде, предшествующем началу военных действий.',
  // },
  // {
  //   model_type: 'specialproject',
  //   slug: 'https://histrf.ru/collections/Soviet-Nuremberg',
  //   image: {
  //     preview: require('~/static/projects/special/nurnberg.png'),
  //   },
  //   title: 'Советский Нюрнберг',
  //   announce:
  //     'Автор проекта — кандидат исторических наук Дмитрий Асташкин — объективно и всесторонне расскажет о том справедливом наказании, которое понесли палачи и их подручные',
  // },
  // {
  //   model_type: 'specialproject',
  //   slug: 'https://histrf.ru/collections/Soviet-Nuremberg',
  //   image: {
  //     preview: require('~/static/projects/special/nurnberg.png'),
  //   },
  //   title: 'Советский Нюрнберг',
  //   announce:
  //     'Автор проекта — кандидат исторических наук Дмитрий Асташкин — объективно и всесторонне расскажет о том справедливом наказании, которое понесли палачи и их подручные',
  // },
  // {
  //   model_type: 'specialproject',
  //   slug: 'https://rzhev.histrf.ru/',
  //   image: {
  //     preview: require('~/static/projects/special/rzhevsckiy-memoreal.png'),
  //   },
  //   title: 'Ржевский мемориал',
  //   announce:
  //     'Мемориальный комплекс в память обо всех солдатах Великой Отечественной войны возведен на месте кровопролитных боёв подо Ржевом 1942-1943 гг., он создан по инициативе ветеранов Великой Отечественной войны, тех, кто воевал здесь, кто удержал и обескровил силы гитлеровской группы армий «Центр».',
  // },
];
