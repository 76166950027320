<template>
  <MainPageBlockWrapperNew
    title="Видеолекции и документальные фильмы"
    href="/watch/lectures"
  >
    <div class="flex flex-col">
      <MainPageCardsReworkedVideoCard
        class="mb-[20px]"
        :data="data[0]"
        is-title-card
        @open:video="handleOpenVideo(data[0])"
      />
      <div class="grid grid-cols-3 gap-[20px]">
        <MainPageCardsReworkedVideoCard
          v-for="(card, index) in data.slice(1, 4)"
          :key="index"
          class="col-span-full lg:col-span-1"
          :data="card"
          @open:video="handleOpenVideo(card)"
        />
      </div>
    </div>
  </MainPageBlockWrapperNew>
</template>

<script setup>
import { Fancybox } from 'fancyapps-ui';

defineProps({
  data: {
    type: Array,
    default: () => [],
  },
});

const handleOpenVideo = (item) => {
  new Fancybox([
    {
      src: item.video_code,
      caption: item.title,
      type: 'iframe',
      preload: false,
      Html: {
        autoSize: false,
      },
      customClass: 'my-iframe',
    },
  ]);
};
</script>
