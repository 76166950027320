export default [
  {
    model_type: 'specialproject',
    slug: 'https://местопамяти.история.рф',
    image: {
      preview: '/projects/other/projects_1.jpg',
    },
    title: '',
    announce: '',
    isBlank: false,
  },
  {
    model_type: 'specialproject',
    slug: 'https://образывойны.рф',
    image: {
      preview: '/projects/other/projects_2.jpg',
    },
    title: '',
    announce: '',
    isBlank: false,
  },
  {
    model_type: 'specialproject',
    slug: 'https://usadba-tatishcheva.ru',
    image: {
      preview: '/projects/other/projects_3.jpg',
    },
    title: '',
    announce: '',
    isBlank: false,
  },
  {
    model_type: 'specialproject',
    slug: 'http://forum.histrf.ru',
    image: {
      preview: '/projects/other/projects_4.jpg',
    },
    title: '',
    announce: '',
    isBlank: false,
  },
  {
    model_type: 'specialproject',
    slug: 'https://anoarvt.ru/tours/besplatnye-ekskursii/',
    image: {
      preview: '/projects/other/projects_5.jpg',
    },
    title: '',
    announce: '',
    isBlank: false,
  },
];
